import React from 'react'

const AddressIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60">
	  <g id="location" transform="translate(0 -5.246)">
	    <g id="Group_3262" data-name="Group 3262" transform="translate(1.031 6.267)">
	      <path id="Path_16709" data-name="Path 16709" d="M50.976,370.217h7.246a2.149,2.149,0,0,1,1.881,1.077l6.25,11.186a2.034,2.034,0,0,1-1.881,2.991H9.776a2.034,2.034,0,0,1-1.881-2.991l6.25-11.186a2.149,2.149,0,0,1,1.881-1.077Z" transform="translate(-7.655 -327.509)" fill="#fff"/>
	      <path id="Path_16710" data-name="Path 16710" d="M169.6,385.47H127s20.333-2.034,25.417-15.253h10.929a2.14,2.14,0,0,1,1.881,1.078l6.253,11.186a2.034,2.034,0,0,1-1.881,2.99Z" transform="translate(-112.784 -327.509)" fill="#fff"/>
	      <path id="Path_16711" data-name="Path 16711" d="M165.535,385.47H127s20.333-2.034,25.417-15.253h6.863a2.14,2.14,0,0,1,1.881,1.078l6.253,11.186a2.034,2.034,0,0,1-1.881,2.99Z" transform="translate(-112.784 -327.509)" fill="#fff"/>
	      <path id="Path_16712" data-name="Path 16712" d="M131.5,31.169c0,2.664-1.21,6.04-3.019,9.6a90.46,90.46,0,0,1-6.639,10.7c-2.42,3.407-4.829,6.477-6.639,8.694-.549.681-1.047,1.271-1.464,1.779a2.027,2.027,0,0,1-3.081.031l-.031-.031c-.417-.508-.915-1.1-1.464-1.779-1.81-2.217-4.229-5.288-6.639-8.694a89.142,89.142,0,0,1-6.639-10.7c-1.81-3.559-3.02-6.935-3.02-9.6a19.32,19.32,0,0,1,11.793-17.826,19.08,19.08,0,0,1,6-1.464c.5-.041,1.017-.061,1.525-.061A19.319,19.319,0,0,1,131.5,31.169Z" transform="translate(-82.716 -11.818)" fill="#98dfff"/>
	      <path id="Path_16713" data-name="Path 16713" d="M128.452,31.62c0,2.664-1.21,6.04-3.02,9.6a90.457,90.457,0,0,1-6.639,10.7c-2.42,3.407-4.829,6.477-6.639,8.694-.549.681-1.047,1.271-1.464,1.779l-.03.031-.031-.031c-.417-.508-.915-1.1-1.464-1.779-1.81-2.217-4.229-5.288-6.639-8.694a89.142,89.142,0,0,1-6.639-10.7c-1.81-3.559-3.02-6.935-3.02-9.6a19.32,19.32,0,0,1,11.793-17.826,19.08,19.08,0,0,1,6-1.464,19.08,19.08,0,0,1,6,1.464A19.311,19.311,0,0,1,128.452,31.62Z" transform="translate(-82.716 -12.269)" fill="#98dfff"/>
	      <g id="Ellipse_127" data-name="Ellipse 127" transform="translate(21.963 9.979)" fill="#fff" stroke="#468e9f" strokeWidth="2">
	        <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
	        <circle cx="7.5" cy="7.5" r="6.5" fill="none"/>
	      </g>
	    </g>
	    <g id="Group_3263" data-name="Group 3263" transform="translate(0 5.246)">
	      <path id="Path_16716" data-name="Path 16716" d="M95.185,46.5c2.512,3.543,5.02,6.711,6.683,8.752.242.3.469.572.688.838H97.54a1.017,1.017,0,0,0,0,2.034h16.267a1.017,1.017,0,0,0,0-2.034H108.79c.22-.265.446-.541.689-.838,1.661-2.034,4.168-5.209,6.682-8.752a89.347,89.347,0,0,0,6.716-10.827c2.076-4.078,3.129-7.463,3.129-10.062a20.325,20.325,0,0,0-5.955-14.4,1.017,1.017,0,1,0-1.439,1.437,18.3,18.3,0,0,1,5.36,12.963c0,2.276-.978,5.351-2.908,9.139A87.337,87.337,0,0,1,114.5,45.329c-2.479,3.5-4.956,6.63-6.6,8.643-.546.669-1.037,1.261-1.456,1.761a1.03,1.03,0,0,1-1.549,0c-.419-.5-.91-1.092-1.456-1.761-1.642-2.012-4.12-5.145-6.6-8.643a87.329,87.329,0,0,1-6.564-10.575c-1.932-3.788-2.908-6.863-2.908-9.139A18.305,18.305,0,0,1,112.8,8.724a1.017,1.017,0,0,0,.794-1.873A20.343,20.343,0,0,0,85.34,25.615c0,2.6,1.053,5.984,3.129,10.067A89.286,89.286,0,0,0,95.185,46.5Z" transform="translate(-75.172 -5.246)" fill="#468e9f"/>
	      <path id="Path_16718" data-name="Path 16718" d="M3.152,380.969h54.7a3.131,3.131,0,0,0,2.737-1.544,2.95,2.95,0,0,0,.032-2.96L54.365,365.28a3.16,3.16,0,0,0-2.766-1.6H44.353a1.017,1.017,0,0,0,0,2.034H51.6a1.127,1.127,0,0,1,.993.555l6.25,11.186a.915.915,0,0,1-.009.94,1.118,1.118,0,0,1-.984.539H3.152a1.118,1.118,0,0,1-.983-.539.915.915,0,0,1-.009-.94l6.25-11.186a1.126,1.126,0,0,1,.992-.555h7.246a1.017,1.017,0,0,0,0-2.034H9.4a3.162,3.162,0,0,0-2.768,1.6L.383,376.466a2.95,2.95,0,0,0,.032,2.96A3.131,3.131,0,0,0,3.152,380.969Z" transform="translate(0 -320.97)" fill="#468e9f"/>
	      <path id="Path_16719" data-name="Path 16719" d="M358,406.351h-4.067a1.023,1.023,0,0,0-.564.171l-3.05,2.034a1.017,1.017,0,0,0,1.129,1.692l2.791-1.863H358a1.017,1.017,0,0,0,0-2.034Z" transform="translate(-308.185 -358.554)" fill="#104d56"/>
	      <path id="Path_16720" data-name="Path 16720" d="M366.937,458.568a1.017,1.017,0,0,0,1.017,1.017h8.133a1.017,1.017,0,0,0,0-2.034h-8.133A1.017,1.017,0,0,0,366.937,458.568Z" transform="translate(-322.22 -403.652)" fill="#104d56"/>
	      <path id="Path_16721" data-name="Path 16721" d="M93.47,410.418a1.017,1.017,0,0,0,.565-1.863l-3.05-2.034a1.022,1.022,0,0,0-.565-.171H86.354a1.017,1.017,0,0,0,0,2.034h3.762l2.794,1.863A1.014,1.014,0,0,0,93.47,410.418Z" transform="translate(-75.17 -358.553)" fill="#104d56"/>
	      <path id="Path_16722" data-name="Path 16722" d="M60.754,457.551a1.017,1.017,0,0,0,0,2.034h8.133a1.017,1.017,0,0,0,0-2.034Z" transform="translate(-52.62 -403.652)" fill="#104d56"/>
	    </g>
	  </g>
	</svg>
)

export default AddressIcon