import React from 'react'
import { Section } from "../../components/Section"
import Map from "../../components/Map"
import BottomImg from "../../images/map-bottom.png"

const MapSection = () =>{
	return(
		<Section pb="0px" mplr="0" mbp="0" bg="#1E4156">
			<div className="container">
				<Map />
      </div>
			<img src={BottomImg} style={{width: "100%", marginBottom: "-10px", marginTop: "-180px"}} alt="bottom of map section" />
		</Section>
	)
}

export default MapSection