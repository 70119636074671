import React from 'react'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import ArticleCarousel from "../components/ArticleCarousel"
import { AboutWrapper, HeroTitle, Section, SectionTitle, BreadCrumb, MarkdownContent } from "../components/Section"
import MapSection from "../sections/About/MapSection"
import AddressIcon from "../components/Icons/AddressIcon"
import EmailIcon from "../components/Icons/EmailIcon"
import CallPhone from "../components/Icons/CallPhone"

const AddressGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	height: 200px;
	@media(max-width: 768px) {
		height: 750px;
		margin-top: 30px;
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 20px;
	}
	@media(max-width: 600px) {
	 	height: 600px;
	 	grid-row-gap: 10px;
	}
	@media(max-width: 440px) {
		height: 500px;
		grid-row-gap: 0px;
	}
`
const GridItem = styled.div`
	padding: 10%;
	border: 1px solid #98DFFF;
	& :hover {
		box-shadow: 0px 15px 45px rgba(152, 223, 255, 0.2);
		border-radius: 5px;
		padding: 15% 10%;
		cursor: pointer;
	}
`
const Icon = styled.div`
	width: 20%;
	display: inline-block;
	vertical-align: middle;
	& svg {
		width: 85%;
	}
	@media(max-width: 1100px) {
		width: 15%;
	}
`
const TextWrap = styled.div`
	width: 80%;
	display: inline-block;
	vertical-align: middle;
	& h4 {
		font-size: 24px;
		line-height: 29px;
		color: #062C44;
		margin-bottom: 5px;
	}
	& p {
		text-align: left;
		font-size: 16px;
		line-height: 22px;
	}
	@media(max-width: 1100px) {
		width: 80%;
		margin-left: 5%;
	}
`
const AddressTitle = styled.h3`
	font-size: 34px;
	line-height: 41px;
	margin-top: 40px;
	margin-bottom: 16px;
`

const ServiceLocationPage = (pageData) =>{
	const data = pageData.data.contentfulServiceLocation
	const AticleSectionData = pageData.data.contentfulServiceLocation.articleSection
	const ArticleData = pageData.data.allContentfulArticle
	return(
		<Layout location={pageData.location}>
      <SEO title={data.metaTitle} description={data.metaDescription} />
      <AboutWrapper>
      	<div className="container">
	      	<BreadCrumb top="-40px" mTop="-40px"><Link to="/">Home</Link> / <span>Service Location </span></BreadCrumb>
	      	<HeroTitle>{data.heroTitle}</HeroTitle>
	      	<p>
	      		<MarkdownContent textAlign="center" dangerouslySetInnerHTML={{__html: data.heroDescription.childMarkdownRemark.html}} />
	      	</p>
      	</div>
      </AboutWrapper>
      <MapSection />
      <Section pt="130px" pb="130px">
      	<SectionTitle>{data.ourOffice.title}</SectionTitle>
      	<p>
      		<MarkdownContent textAlign="center" dangerouslySetInnerHTML={{__html: data.ourOffice.description.childMarkdownRemark.html}} />
      	</p>
      	<div className="container">
      		{
      			data.ourOffice.features.map(content=>{
      				return(
      					<div>
      						<AddressTitle>{content.title}</AddressTitle>
      						<AddressGrid>
				      			<GridItem>
				      				<Icon><AddressIcon /></Icon>
				      				<TextWrap>
				      					<h4>Address</h4>
				      					<p>{content.address}</p>
				      				</TextWrap>
				      			</GridItem>
				      			<GridItem>
				      			<a href={`mailto:${content.email}`}>
				      				<Icon><EmailIcon /></Icon>
				      				<TextWrap>
				      					<h4>Email Address</h4>
				      					<p>{content.email}</p>
				      				</TextWrap>
				      			</a>
				      			</GridItem>
				      			<GridItem>
					      			<a href={`tel:${content.phone}`}>
					      				<Icon><CallPhone /></Icon>
					      				<TextWrap>
					      					<h4>Contact Number</h4>
					      					<p>{content.phone}</p>
					      				</TextWrap>
					      			</a>
				      			</GridItem>
				      		</AddressGrid>
      					</div>
      				)
      			})
      		}
      	</div>
      </Section>
     	<ArticleCarousel sectionData={AticleSectionData} data={ArticleData} />
      <CallToAction />
    </Layout>
	)
}

export default ServiceLocationPage

export const pageQuery = graphql`
  query ServiceLocationPageQuery {
	  contentfulServiceLocation {
	    title
	    metaTitle
	    metaDescription
	    heroTitle
	    heroDescription {
	      childMarkdownRemark {
	        html
	      }
	    }
	    ourOffice {
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	      features {
	        title
	        address
	        email
	        phone
	      }
	    }
	    articleSection {
	    	title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	    }
	  }
	  allContentfulArticle {
      edges {
        node {
          title
          publishDate(formatString: "DD, MMMM YYYY")
          description {
            description
          }
          tumbnail {
            fluid(maxHeight: 700) {
              base64
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
              aspectRatio
            }
          }
          category {
            name
          }
          createdAt(fromNow: true)
        }
      }
    }
	}
`

