import React from 'react'

const CallPhone = (props)=>(
<svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60">
  <g id="call_1_" data-name="call (1)" transform="translate(-0.643 -1.018)">
    <g id="Group_3260" data-name="Group 3260" transform="translate(1.55 2.022)">
      <path id="Path_16703" data-name="Path 16703" d="M65.842,57.116,62.76,62.2a6.332,6.332,0,0,1-2.1,2.12,5.909,5.909,0,0,1-1.586.69c-5.113,1.39-16.4,1.68-34.488-16.229S6.778,19.709,8.183,14.649a5.785,5.785,0,0,1,.7-1.57A6.335,6.335,0,0,1,11.022,11l5.133-3.05a2.576,2.576,0,0,1,3.446.77l4.456,6.6,3.729,5.54a2.536,2.536,0,0,1-.556,3.41L26,25.218,22.633,27.8a2.527,2.527,0,0,0-.677,3.22c1.354,2.4,2.627,5.23,8.953,11.5s9.2,7.519,11.621,8.859a2.59,2.59,0,0,0,3.254-.67l2.607-3.33.96-1.22a2.6,2.6,0,0,1,3.446-.55l5.77,3.81,6.5,4.29A2.517,2.517,0,0,1,65.842,57.116Z" transform="translate(-7.128 -7.589)" fill="#fff"/>
      <path id="Path_16704" data-name="Path 16704" d="M63.826,74.182l-3.082,5.08c-.03.04-.051.08-.081.12a5.908,5.908,0,0,1-1.586.69c-5.113,1.39-18.947,3.139-37.035-14.77S6.783,34.775,8.187,29.715a5.785,5.785,0,0,1,.7-1.57c.04-.03.081-.05.121-.08l5.133-3.05a2.576,2.576,0,0,1,3.446.77l4.456,6.6,3.729,5.54a2.554,2.554,0,0,1-.556,3.41l-3.385,2.6-1.213.93a2.521,2.521,0,0,0-.677,3.22c1.354,2.4,2.627,5.23,8.953,11.5s9.2,7.519,11.621,8.859a2.583,2.583,0,0,0,3.254-.67l.94-1.2,2.627-3.35a2.618,2.618,0,0,1,3.446-.55l5.77,3.81,6.5,4.29A2.517,2.517,0,0,1,63.826,74.182Z" transform="translate(-7.133 -22.656)" fill="#98dfff"/>
    </g>
    <g id="Group_3261" data-name="Group 3261" transform="translate(0.643 1.018)">
      <path id="Path_16705" data-name="Path 16705" d="M49.012,61.018a18.081,18.081,0,0,0,4.749-.614,7.223,7.223,0,0,0,4.284-3.267l3.086-5.076a3.5,3.5,0,0,0-1.08-4.756l-12.274-8.1a3.608,3.608,0,0,0-4.8.771l-3.575,4.549a1.539,1.539,0,0,1-1.959.408l-.68-.371c-2.254-1.216-5.06-2.73-10.711-8.324s-7.185-8.372-8.413-10.6l-.373-.673a1.5,1.5,0,0,1,.4-1.946l4.594-3.537a3.522,3.522,0,0,0,.78-4.747L14.862,2.592a3.587,3.587,0,0,0-4.8-1.07L4.926,4.573a7.175,7.175,0,0,0-3.3,4.24C-.223,15.476-1.263,29.738,15.857,46.681,29.475,60.156,42.044,61.018,49.012,61.018ZM3.574,9.342A5.184,5.184,0,0,1,5.966,6.288L11.1,3.241a1.556,1.556,0,0,1,2.085.464l4.446,6.605,3.732,5.539a1.528,1.528,0,0,1-.337,2.06l-4.595,3.537a3.476,3.476,0,0,0-.937,4.492l.365.656c1.291,2.344,2.9,5.26,8.759,11.059s8.808,7.388,11.175,8.666l.664.362a3.561,3.561,0,0,0,4.539-.927l3.574-4.547a1.567,1.567,0,0,1,2.082-.334l12.273,8.1a1.519,1.519,0,0,1,.469,2.063l-3.079,5.079a5.215,5.215,0,0,1-3.082,2.364c-6.217,1.689-19.508,3.052-35.943-13.212S1.867,15.494,3.574,9.342Z" transform="translate(-0.643 -1.018)" fill="#468e9f"/>
      <path id="Path_16706" data-name="Path 16706" d="M283.638,71.324a17.109,17.109,0,0,1,17.179,17,1.011,1.011,0,0,0,2.021,0,19.121,19.121,0,0,0-19.2-19,1,1,0,1,0,0,2Z" transform="translate(-248.917 -61.32)" fill="#104d56"/>
      <path id="Path_16707" data-name="Path 16707" d="M283.638,122.524a11.071,11.071,0,0,1,11.116,11,1.011,1.011,0,0,0,2.021,0,13.083,13.083,0,0,0-13.137-13,1,1,0,1,0,0,2Z" transform="translate(-248.917 -106.52)" fill="#104d56"/>
      <path id="Path_16708" data-name="Path 16708" d="M283.638,173.724a5.032,5.032,0,0,1,5.053,5,1.011,1.011,0,0,0,2.021,0,7.045,7.045,0,0,0-7.073-7,1,1,0,1,0,0,2Z" transform="translate(-248.917 -151.721)" fill="#104d56"/>
    </g>
  </g>
</svg>

)

export default CallPhone