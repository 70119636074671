import React from 'react'

const EmailIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
  <g id="paper-plane_1_" data-name="paper-plane (1)" transform="translate(0 0)">
    <path id="Path_16723" data-name="Path 16723" d="M305.132,19,281.907,52.528l-2.421,3.495V67.758l8.825-8.625L283.8,50.719" transform="translate(-246.733 -16.774)" fill="#fff"/>
    <path id="Path_16724" data-name="Path 16724" d="M81.051,45.568,64,40.352,115.328,10V56.055L89.664,48.2" transform="translate(-56.5 -8.828)" fill="#98dfff"/>
    <path id="Path_16725" data-name="Path 16725" d="M221.1,67.758l-7.75-14.106L246.749,19,221.823,54.983Z" transform="translate(-188.35 -16.774)" fill="#fff"/>
    <path id="Path_16726" data-name="Path 16726" d="M107.08.154A1.172,1.172,0,0,0,105.9.163l-21.475,12.7a1.172,1.172,0,1,0,1.193,2.017l15.408-9.111-28.7,29.779L58.055,31.18,76.683,20.164a1.172,1.172,0,0,0-1.193-2.018L54.575,30.514a1.172,1.172,0,0,0,.254,2.129l17.047,5.215L79.4,51.548a.278.278,0,0,0,.024.037,1.169,1.169,0,0,0,1.822.237L89.89,43.37l16.266,4.976a1.172,1.172,0,0,0,1.515-1.121V1.172A1.172,1.172,0,0,0,107.08.154ZM96.841,13.49,79.46,38.581a1.172,1.172,0,0,0-.208.667v7.168L74.116,37.07ZM81.6,48.2V40.833l5.741,1.756Zm.7-9.6L105.328,5.356V45.642Z" transform="translate(-47.671 0)" fill="#468e9f"/>
    <path id="Path_16727" data-name="Path 16727" d="M66.94,344.991a1.172,1.172,0,0,0-1.657,0l-10.94,10.94A1.172,1.172,0,0,0,56,357.588l10.94-10.94A1.172,1.172,0,0,0,66.94,344.991Z" transform="translate(-47.671 -304.26)" fill="#104d56"/>
    <path id="Path_16728" data-name="Path 16728" d="M3.039,469.344l-2.7,2.7A1.172,1.172,0,1,0,2,473.7L4.7,471a1.172,1.172,0,1,0-1.657-1.657Z" transform="translate(0 -414.04)" fill="#104d56"/>
    <path id="Path_16729" data-name="Path 16729" d="M126.752,491.41a1.172,1.172,0,1,0,.828.343A1.178,1.178,0,0,0,126.752,491.41Z" transform="translate(-110.863 -433.823)" fill="#104d56"/>
    <path id="Path_16730" data-name="Path 16730" d="M156.672,417.349l-5.794,5.794a1.172,1.172,0,1,0,1.657,1.657l5.794-5.794a1.172,1.172,0,0,0-1.657-1.657Z" transform="translate(-132.893 -368.139)" fill="#104d56"/>
    <path id="Path_16731" data-name="Path 16731" d="M327.207,413.554l-5.81,5.81a1.172,1.172,0,1,0,1.657,1.657l5.81-5.81a1.172,1.172,0,1,0-1.657-1.657Z" transform="translate(-283.43 -364.788)" fill="#104d56"/>
    <path id="Path_16732" data-name="Path 16732" d="M267.6,135.724a1.172,1.172,0,1,0-.829-.343A1.176,1.176,0,0,0,267.6,135.724Z" transform="translate(-235.207 -117.75)" fill="#104d56"/>
  </g>
</svg>

)

export default EmailIcon