import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import LazyLoad from "react-lazyload"
import Img from "gatsby-image"

const Wrap = styled.div`
  width: 100%;
  height: 800px;
  & .gatsby-image-wrapper {
    max-width: 1200px;
    margin: auto;
  }
  @media(max-width: 768px) {
    height: auto;
  }
`


export const MapImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "map.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default class Map extends React.Component {
  render() {
    return (
      <Wrap>
        <LazyLoad>
          <MapImage />
        </LazyLoad>
      </Wrap>
    )
  }
}

